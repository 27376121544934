<template>
  <div class="poster-page" :class="(imgUrl_base64&&imgUrl_base64.length>0)?'':'img-blank'" :style="cardsStyle">
    <h1>预览：<br class="pc"><span>[点击图片查看大图]</span></h1>
    <div class="show-area">
      <!-- {{ isCut }} -->
      <img class="card-preview" :src="imgUrl_base64" alt="" v-if="imgUrl_base64&&imgUrl_base64.length>0" @click="isPreview=true">
      <!-- <Model class="card-hide" @isready="setInfo" :_kind="_kind" :_cardid="_cardid" :_id="_id" :_originRenderData="_info" :key="_kind+_cardid+_id" :class="_kind+_cardid+_id"></Model> -->
      <Renders 
        :_selectparams="_selectparams"
        :_activedata="_info"
        :_origindata="_origininfo"
        :key="_kind+_cardid+_id"
        :class="'card-hide '+(_kind+_cardid+_id)"
        @isready="setCardInfo"
      ></Renders>
      <!-- <p style="overflow:auto; width: 100%; height: 100%; word-break: break-all;">
        {{ _selectparams }}<br><br>⭐️⭐️<br><br>
        {{ _origininfo }}<br><br>⭐️⭐️<br><br>
        {{ _info }}
      </p> -->
    </div>
    <div class="show-menus">
      <div class="menus">
        <div class="menu" @click="shareImg">
          <Icon :_type="'share'" :_size="buttonSize" :_color="iconColor"></Icon>
          {{ shareLimit ? '分享海报' : '复制链接'}}
        </div>
        <div class="menu" @click="downloadImg">
          <Icon :_type="'down'" :_size="buttonSize" :_color="iconColor"></Icon>
          保存海报
        </div>
        <!-- <div class="menu" @click="refreshImg()">
          <Icon :_type="'img'" :_size="buttonSize" :_color="iconColor"></Icon>
          重新生成
        </div> -->
      </div>
    </div>
    <div class="img-preview" v-if="isPreview&&imgUrl_base64&&imgUrl_base64.length>0" @click="isPreview=false">
      <img :src="imgUrl_base64" alt="">
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import mixinUpload from '../../utils/mixinUpload.js'
import mixinPosterBase from '../../utils/mixinPosterBase.js'
import { getSue, getSueArr, getSueLecturer, cardSueId, cardSueType } from '../../utils/api.js'

export default {
  name: 'PosterPage',
  components: {
    Icon: ()=>import('../Icon.vue'),
    Renders: ()=>import('./Renders.vue'),
    // Model: () => import('./Render.vue'),
    // Model: () => import('./Render-copy-3.vue'),
  },
  props: {
    // _year: [String, Number],
    // _kind: {
    //   type: String,
    //   default: ''
    // },
    // _cardid: [String, Number],
    // _id: [String, Number],
    _selectparams: [Object, Array, String],
    _info: [Object, Array, String],
    _origininfo: [Object, Array],
  },
  mixins: [ mixinUpload, mixinPosterBase ],
  data() {
    return {
      isPreview: false,
      imgUrl_base64: '', // url-base64 // 测试图片- 'https://f.msup.com.cn/1718939071236.share202406215356229.png',
      imgUrl: '',  // url
      imgFile: '', // file
      imgBlob: '', // blob
      imgName: '', // name
      buttonSize: 18,
      buttonColor: '#ffffff', // '#003177',
      buttonBgColor: '#003177', // rgba(255,255,255,1),
      iconColor: '#eee',
      selectedparams: {}, // { year:false, sid:false, type:false, cardId:false, cardDetailId:false, venueId:false, courseId:false, scheduleId:false },
    }
  },
  computed: {
    isCut() {
      return (this.imgUrl_base64 && this.imgUrl_base64.length>0) || false;
    },
    _year() {
      return this.selectedparams.year;
    },
    _kind() {
      return this.selectedparams.type && this.selectedparams.type.toLocaleLowerCase();
    },
    _cardid() {
      return this.selectedparams.cardId;
    },
    _id() {
      let id = this.selectedparams.cardDetailId || ( // 》》》》》》
        (this.selectedparams.type=='chairman'||this.selectedparams.type=='producer')&&this.selectedparams.lecturerId
      ) || (
        this.selectedparams.type=='subject'&&this.selectedparams.venueId
      ) || (
        this.selectedparams.type=='course'&&this.selectedparams.courseId
      ) || (
        this.selectedparams.type=='case'&&'case'
      ) || (
        (this.selectedparams.type=='schedule'&&this.selectedparams.scheduleId!==false) ? this.selectedparams.scheduleId : false
      );
      if(!(id || id===0 || id==='0')) {
        id = false;
      }
      return id;
    },
    _secondaryId () {
      let type = this.selectedparams.type || ''
      let key = (type && this.secondaryKeys[type]) || ''
      let id = this.selectedparams[key] || ''
      return id
    },
    shareLimit() {
      return 'share' in navigator;
    },
    cardsStyle() {
      let style = '';
      style += `--color-main: ${this.buttonBgColor};`;
      style += `--color-button: ${this.buttonColor};`;
      return style;
    },
    originParams() {
      return [this._kind, this._cardid, this._id];
    }
  },
  watch: {
    _selectparams: {
      handler(_new, _old) {
        if(_new) {
          if(typeof _new==='string' && _new.includes('{')){
            this.selectedparams = JSON.parse(_new);
          } else {
            this.selectedparams = _new;
          }
        }
      },
      immediate: true,
      deep: true
    },
    originParams: {
      handler(_new, _old) {
        // console.log('Page·watch·origin_params ', _new, '', _old);
        if(!_old || _old[0]!=_new[0] || _old[1]!=_new[1] || _old[2]!=_new[2]) {
          this.isPreview = false;
          this.imgUrl_base64 = '';
          this.imgUrl = '';
          this.imgFile = '';
          this.imgBlob = '';
          this.imgName = '';
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    toStop(e) {
      e.stopPropagation();
    },

    setCardInfo(res) {
      console.log('👌🏻act-page ', res);
      if(res && res.status && res.status == '200') {
        setTimeout(() => {
          this.setCard(); // 》》》》》》
        }, 500);
        this.$emit('setinfo', res.data);
      }
    },

    shareImg(e) {
      if(e) {
        this.toStop(e);
      }
      // this.global.IsPC()
      // pc - 上传图片至七牛云--复制七牛云链接
      // mob - 唤起分享功能 or 长按链接复制分享
        this.qiniuUpload(this.imgFile).then(res => {
          console.log('Page·method·share_img ', res);
          if(res && res.qiniuDomain && res.result && res.result.key) {
            let linkUrl = res.qiniuDomain + res.result.key;
            if (this.shareLimit) {
              navigator.share({
                  title: this.imgName,
                  text: '',
                  url: linkUrl
              })
              .then(() => console.log('分享成功'))
              .catch(error => console.log('分享失败', error));
            } else {
              this.copyUrl(linkUrl);
            }
          } else {
            this.$toast('海报链接获取失败，请稍后重试');
          }
        })
    },
    downloadImg(e) {
      if(e) {
        this.toStop(e);
      }
      // alert(this.global.IsPC() + ' ' + this.imgUrl);
      if(this.imgUrl && this.imgUrl.length>0) {
        if(this.global.IsPC()) {
          let path = '/download?url=' + this.imgUrl + '&name=' + (this.imgName||this._kind) + '.png';
          // let path = '/download'
          window.open(path, '_blank');
        } else {
          if(this.global.isWeChatBrowser()) {
            alert('请点击右上角，进入浏览器进行下载');
          } else {
            // console.log( $('.card-download-poster') );
            document.getElementsByClassName('card-download-poster')[0].click();
          }
        }
      }
    },

    setCard() {
      // return new Promise((resolve, reject) => {
        // 给图片添加属性 crossOrigin="Anonymous" 应对图片跨域问题
        // id="app" 的Dom截图-  document.querySelector("#app")
        // 若改为标签body的截图-  document.body
        let modelId = `#sharecard_model_${this._kind.toLocaleLowerCase()}_${this._cardid}_${this._id}_${this._secondaryId}`; // Renders.vue
        // let modelId = '.sharecard_model';
        console.log(modelId);
        let canvasID = document.querySelector(modelId);
        html2canvas(canvasID, {
          useCORS: true, // 开启跨域配置
          // scale: window.devicePixelRatio < 3 ? window.devicePixelRatio : 2,
          scale: 2, // 将输出图像的分辨率设置为屏幕分辨率的两倍
          allowTaint: true, // 允许跨域图片
          dpi: 300, // 处理模糊问题
        }).then((canvas) => {
          //获取年月日作为文件名-年月日加随机数
          var timers = new Date();
          var fullYear = timers.getFullYear();
          var month = timers.getMonth() + 1;
          var date = timers.getDate();
          var randoms = Math.random() + '';
          var theTime = fullYear + '' + ( month < 10 ? '0' : '' ) + month + ( date < 10 ? '0' : '' ) + date;
          this.imgName = `share${theTime}${randoms.slice(3, 10)}`;
          // dom
          let dom = document.body.appendChild(canvas);
          dom.style.display = 'none';
          document.body.removeChild(dom);
          // console.log('📢 set_card', dom, dom.toDataURL('image/png'));
          this.imgBlob = this.global.dataURLToBlob(dom.toDataURL('image/png', 1.0));
          // url file
          this.imgUrl = window.URL.createObjectURL(this.imgBlob);
          this.imgFile = new File([this.imgBlob], this.imgName, {
            type: 'image/png', // 'application/json',
            lastModified: Date.now()
          });
          this.global.triggerFile(this.imgFile).then(url => {
            if(url) {
              this.imgUrl_base64 = url;
            }
          })
        })
      // })
    }
  }
}
</script>

<style lang="scss">
.poster-page {
  // width: auto;
  width: 100%;
  height: 100%;
  padding: 30px 45px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  z-index: 5;
  --background-color-button: linear-gradient(90deg, #7987E8 0%, #00C3FE 100%);
  --opacity-spread: 0.75;
  --opacity-close: 0.6;
  &.img-blank::after {
    content: '加载中...';
    font-weight: bold;
    color: #666;
    position: absolute;
    top: calc(50% - 30px);
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
  }
  h1, .show-area, .show-menus {
    position: relative;
    z-index: 5;
  }
  >h1 {
    height: 100%;
    margin-right: 40px;
    span {
      font-size: 15px;
      font-weight: 400;
      opacity: 0.35;
    }
  }
  .show-area {
    // width: 100%;
    // height: 100%;
    margin-right: 50px;
    .card-preview {
      width: auto;
      height: auto;
      max-height: calc(100vh - 60px);
    }
    .card-hide {
      position: fixed!important;
      bottom: 100vh!important;
    }
  }
  .show-menus {
    height: 100%;
    display: grid;
    align-items: end;
    // .menus {
    //   height: 100px;
    // }
    .menu {
      margin-top: 10px;
      width: fit-content;
      height: fit-content;
      padding: 7px 12px;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      color: var(--color-button);
      // text-shadow: 0 1px 2px #fff;
      text-shadow: 0 1px 2px var(--color-main);
      user-select: none;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--color-button);
      // border: 1px solid var(--color-main);
      background: var(--background-color-button);
      opacity: var(--opacity-close);
      cursor: pointer;
      .icon-defined {
        margin-right: 8px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .img-preview {
    width: 100vw;
    height: 100vh;
    text-align: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1200;
    overflow: auto;
    background-color: rgba(0,0,0,0.25);
    img {
      width: 90%;
      max-width: 700px;
      height: auto;
      margin: 75px 30px 30px 30px;
    }
  }
}
</style>